import { toast } from 'react-hot-toast'
import { DateTime } from 'luxon'
import { CouponType } from '../../../types'

const COUPONS: CouponType[] = [
  {
    code: 'SCLOWRATE',
    discount: {
      year: {
        id: 'mFi9MjGI',
        code: 'SCLOWRATEYEAR',
        amount: 100
      },
      month: {
        id: 'i2gEaKvA',
        code: 'SCLOWRATEMONTH',
        amount: 10
      }
    },
    // starts: DateTime.fromISO('2024-06-16T00:00:00.000').toSeconds(),
    expires: DateTime.fromISO('2023-06-21T17:00:00.000').toSeconds(),
    // autoApply: true,
    isActive: false
  },
  {
    code: 'ANNUALPROMO',
    discount: {
      year: {
        id: 'Ow0u69rU',
        code: 'ANNUALPROMO',
        amount: 100
      }
    },
    expires: DateTime.fromISO('2023-11-28T01:01:00+0000').toSeconds(),
    requiresPSD: false,
    isActive: true
  },
  {
    code: 'STARTNOW',
    discount: {
      year: {
        id: 'evergreen-a-year',
        code: 'EVERGREENAYEAR',
        amount: 100
      },
      month: {
        id: 'evergreen-a-month',
        code: 'EVERGREENAMONTH',
        amount: 10
      }
    },
    // expires: DateTime.fromISO('2026-06-20T00:00:00.000').toSeconds(),
    // requiresPSD: true,
    starts: DateTime.fromISO('2024-06-16T00:00:00.000-0700').toSeconds(),
    expires: DateTime.fromISO('2030-06-21T17:00:00.000-0700').toSeconds(),
    autoApplyEnd: DateTime.fromISO('2024-06-21T17:00:00.000-0700').toSeconds(),
    autoApply: true,
    isActive: true
  },
  {
    code: 'SAVEONSC',
    discount: {
      year: {
        id: 'evergreen-a-year',
        code: 'EVERGREENAYEAR',
        amount: 100
      },
      month: {
        id: 'evergreen-a-month',
        code: 'EVERGREENAMONTH',
        amount: 10
      }
    },
    // expires: DateTime.fromISO('2026-06-20T00:00:00.000').toSeconds(),
    // requiresPSD: true,
    starts: DateTime.fromISO('2024-08-18T00:00:00.000-0700').toSeconds(),
    expires: DateTime.fromISO('2024-08-23T17:00:00-07:00').toSeconds(),
    autoApplyEnd: DateTime.fromISO('2024-08-23T17:00:00.000-0700').toSeconds(),
    autoApply: true,
    isActive: true
  }
]

// PSD: Promotion Start Date

const PSD_TO_ADD = 7

const getPSDDate = (psd?: string) => {
  if (!psd) {
    return
  }

  const format = DateTime.fromFormat(psd, 'MM/dd/yyyy HH:mm', { zone: 'America/Los_Angeles' })

  if (format !== null) {
    return format
  }

  const iso = DateTime.fromFormat(psd, 'MM/dd/yyyy HH:mm', { zone: 'America/Los_Angeles' })

  if (iso !== null) {
    return iso
  }
}

const checkForAutoApplyCoupon = () => {
  const autoApplyCoupons = COUPONS.filter((c) => c.autoApply)

  if (!autoApplyCoupons.length) {
    return
  }

  const coupon = autoApplyCoupons.find((c) => {
    if (!c.starts) {
      return true
    }

    const validStart = DateTime.fromSeconds(c.starts).diffNow('seconds').seconds < 0

    if (validStart && !c.autoApplyEnd) {
      return true
    }

    const validEnd = DateTime.fromSeconds(c.autoApplyEnd || 0).diffNow('seconds').seconds > 0

    if (validEnd) {
      return true
    }

    return false
  })

  return coupon
}

export const getCoupon = (code?: string, psd?: string, warn: boolean = true) => {
  if (!code) {
    return checkForAutoApplyCoupon()
  }

  const coupon = COUPONS.find((c) => c.code === code)

  if (!coupon) {
    warn && toast.error('That coupon code does not exist')
    return
  }

  if (!coupon.isActive) {
    warn && toast.error('That coupon code is no longer active')
    return
  }

  if (coupon.expires < DateTime.now().toSeconds()) {
    warn && toast.error('That coupon code has expired')
    return
  }

  if (coupon.requiresPSD && !psd) {
    warn && toast.error('That coupon code has expired')
    return
  }

  const psdDate = getPSDDate(psd)

  if (psdDate && Math.abs(psdDate.diff(DateTime.now().endOf('day'), ['days']).days) > PSD_TO_ADD) {
    warn && toast.error('That coupon code has expired')
    return
  }

  warn && toast.success('Added coupon code')

  return {
    ...coupon,
    expires: !!psdDate ? psdDate.plus({ days: PSD_TO_ADD }).toSeconds() : coupon.expires
  } as CouponType
}
